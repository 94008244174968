// @flow
import types from './quoteConstants';

const INIT_STATE = {
    quotes: [],
    quote: {id: 0},
    historyList: [],
    hasNextPage: false,
    totalPrice: 0,
    quoteCounts: {},
    processPayment: null,
    loading: false,
    historyLoading: false,
    success: null
};

const Quote = (state = INIT_STATE, action) => {
    switch (action.type) {
        case types.CLEAR_STATE:
            return {
                ...INIT_STATE
            }

        //Get Quotes
        case types.GET_QUOTES:
            return {
                ...state,
                loading: true
            };
        case types.GET_QUOTES_SUCCESS:
            return {
                ...state,
                quotes: action.payload.data.items,
                hasNextPage: action.payload.data.hasNextPage,
                totalPrice: action.payload.data.totalPrice,
                quoteCounts: action.payload.data.quoteCounts,
                loading: false,
                error: null
            };
        case types.GET_QUOTES_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        //Get Quotes

        //Get Quote
        case types.GET_QUOTE:
            return {
                ...state,
                loading: true
            };
        case types.GET_QUOTE_SUCCESS:
            return {
                ...state,
                quote: action.payload.data,
                loading: false,
                error: null
            };
        case types.GET_QUOTE_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        //Get Quote

        //Patch Quote
        case types.PATCH_QUOTE:
            return {
                ...state,
                loading: true,
                error: null,
                success: null
            };
        case types.PATCH_QUOTE_SUCCESS:
            return {
                ...state,
                loading: false,
                quote: action.payload.data,
                success: "QUOTE.PATCH_QUOTE_SUCCESS",
                error: null
            };
        case types.PATCH_QUOTE_FAILED:
            return {
                ...state,
                success: null,
                error: action.payload,
                loading: false
            };
        //Patch Quote

        //Post Quote
        case types.POST_QUOTE:
            return {
                ...state,
                loading: true
            };
        case types.POST_QUOTE_SUCCESS:
            return {
                ...state,
                loading: false,
                quote: action.payload.data,
                success: "QUOTE.POST_QUOTE_SUCCESS",
                error: null
            };
        case types.POST_QUOTE_FAILED:
            return {
                ...state,
                success: null,
                error: action.payload,
                loading: false
            };
        //Post Quote

        //Put Quote Items
        case types.PUT_QUOTE_ITEMS:
            return {
                ...state,
                loading: true
            };
        case types.PUT_QUOTE_ITEMS_SUCCESS:
            return {
                ...state,
                loading: false,
                success: "QUOTE.PUT_QUOTE_ITEMS_SUCCESS",
                error: null
            };
        case types.PUT_QUOTE_ITEMS_FAILED:
            return {
                ...state,
                success: null,
                error: action.payload,
                loading: false
            };
        //Put Quote Items

        //Post Quote Email
        case types.POST_QUOTE_EMAIL:
            return {
                ...state,
                loading: true
            };
        case types.POST_QUOTE_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                success: "QUOTE.POST_QUOTE_EMAIL_SUCCESS",
                error: null
            };
        case types.POST_QUOTE_EMAIL_FAILED:
            return {
                ...state,
                success: null,
                error: action.payload,
                loading: false
            };
        //Post Quote Email

        //Post Quote Accept
        case types.POST_QUOTE_ACCEPT:
            return {
                ...state,
                loading: true
            };
        case types.POST_QUOTE_ACCEPT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: "QUOTE.POST_QUOTE_ACCEPT_SUCCESS",
                error: null
            };
        case types.POST_QUOTE_ACCEPT_FAILED:
            return {
                ...state,
                success: null,
                error: action.payload,
                loading: false
            };
        //Post Quote Accept

        //Post Quote Invoiced
        case types.POST_QUOTE_INVOICED:
            return {
                ...state,
                loading: true
            };
        case types.POST_QUOTE_INVOICED_SUCCESS:
            return {
                ...state,
                loading: false,
                success: "QUOTE.POST_QUOTE_INVOICED_SUCCESS",
                error: null
            };
        case types.POST_QUOTE_INVOICED_FAILED:
            return {
                ...state,
                success: null,
                error: action.payload,
                loading: false
            };
        //Post Quote Invoiced

        //Post Quote Rejected
        case types.POST_QUOTE_REJECT:
            return {
                ...state,
                loading: true
            };
        case types.POST_QUOTE_REJECT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: "QUOTE.POST_QUOTE_REJECT_SUCCESS",
                error: null
            };
        case types.POST_QUOTE_REJECT_FAILED:
            return {
                ...state,
                success: null,
                error: action.payload,
                loading: false
            };
        //Post Quote Rejected

        //Post Quote Accept
        case types.POST_QUOTE_ACCEPT:
            return {
                ...state,
                loading: true
            };
        case types.POST_QUOTE_ACCEPT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: "QUOTE.POST_QUOTE_ACCEPT_SUCCESS",
                error: null
            };
        case types.POST_QUOTE_ACCEPT_FAILED:
            return {
                ...state,
                success: null,
                error: action.payload,
                loading: false
            };
        //Post Quote Accept

        //Post Quote Convert to Order
        case types.POST_QUOTE_CONVERT_TO_ORDER:
            return {
                ...state,
                loading: true
            };
        case types.POST_QUOTE_CONVERT_TO_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                success: "QUOTE.POST_QUOTE_CONVERT_TO_ORDER_SUCCESS",
                error: null
            };
        case types.POST_QUOTE_CONVERT_TO_ORDER_FAILED:
            return {
                ...state,
                success: null,
                error: action.payload,
                loading: false
            };
        //Post Quote Convert to Order

        //history
        case types.GET_HISTORY:
            return {
                ...state,
                historyLoading: true,
                error: null
            };
        case types.GET_HISTORY_SUCCESS:
            return {
                ...state,
                historyList: action.payload.data,
                historyLoading: false,
                error: null
            };
        //history

        default:
            return state;
    }
};

export default Quote;
